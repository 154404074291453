import {createBrowserRouter, Navigate, Outlet, useLocation} from "react-router-dom";
import React, {useEffect} from "react";
import {setToken, useToken} from "./hooks/useToken";
import {useUser} from "./hooks/useUser";
import {wait} from "./utils/waiter";
import APIClient, {UserRole} from "./api";
import Home from "./pages/Home";
import Login from "./pages/Login";
import Layout from "./pages/Layout";
import BotList from "./pages/bot/BotList";
import {BsRobot} from "react-icons/bs";
import KnowledgeBaseList from "./pages/knowledge-base/KnowledgeBaseList";
import KnowledgeList from "./pages/knowledge-base/KnowledgeList";
import BotEditor from "./pages/bot/BotEditor";
import Phone from "./pages/Phone";

function RouteProtect() {
  const location = useLocation();
  const [token] = useToken();
  const [user, setUser] = useUser();
  useEffect(() => {
    (async () => {
      try {
        const waiter = wait(500);
        const user = await APIClient.user.current();
        await waiter;
        setUser(user);
      } catch (e) {
        setToken(undefined);
        setUser(null);
      }
    })()
  }, [token, setUser])
  //判断是否授权
  const returnUrl = encodeURIComponent(`${location.pathname}${location.search}`);
  //token都没有,直接GG
  if (typeof token === "undefined")
    return <Navigate to={`/login?returnUrl=${returnUrl}`} replace/>;
  //user还没有获取到,等一等
  if (typeof user === "undefined")
    return (
      <div className="h-full flex justify-center items-center">
        <BsRobot size={54}/>
      </div>
    )
  //user已经获取到,但是不存在,直接前往登陆页面
  if (user === null)
    return <Navigate to={`/login?returnUrl=${returnUrl}`} replace/>;
  if (user.phone === null)
    return <Navigate to={`/phone?returnUrl=${returnUrl}`} replace/>;
  //正常路由
  return <Outlet/>
}

function AdminGuard() {
  const [user] = useUser();
  if (user?.role !== UserRole.Admin) {
    return <Navigate to={`/console/knowledge-base`}/>;
  }
  return <Outlet/>
}

export const router = createBrowserRouter([
  {
    index: true,
    element: <Home/>
  },
  {
    path: "/phone",
    element: <Phone/>
  },
  {
    path: "/login",
    element: <Login/>
  },
  {
    path: "/console",
    element: <RouteProtect/>,
    children: [
      {
        element: <Layout/>,
        children: [
          {
            index: true,
            element: <Navigate to="bot" replace/>
          },
          {
            path: "bot",
            element: <AdminGuard/>,
            children: [
              {
                index: true,
                element: <BotList/>
              },
              {
                path: ":botId",
                element: <BotEditor/>
              }
            ]
          },
          {
            path: "knowledge-base",
            children: [
              {
                index: true,
                element: <KnowledgeBaseList/>
              },
              {
                path: ":knowledgeBaseId",
                children: [
                  {
                    path: "knowledge",
                    children: [
                      {
                        index: true,
                        element: <KnowledgeList/>
                      }
                    ]
                  }
                ]
              }
            ]
          }
        ]
      }
    ]
  },
]);

import {Link, Outlet, useLocation, useNavigate} from "react-router-dom";
import {BsRobot} from "react-icons/bs";
import {
  Button,
  Dropdown,
  DropdownItem,
  DropdownMenu,
  DropdownTrigger,
  Input,
  Modal,
  ModalBody,
  ModalContent,
  ModalFooter,
  ModalHeader,
  useDisclosure
} from "@nextui-org/react";
import React, {useState} from "react";
import {FaRegUser} from "react-icons/fa";
import {setToken} from "../hooks/useToken";
import {useUser} from "../hooks/useUser";
import {RiRobot2Line} from "react-icons/ri";
import classNames from "classnames";
import {SlBookOpen} from "react-icons/sl";
import {wait} from "../utils/waiter";
import APIClient, {UserRole} from "../api";

export default function Layout() {
  const [user] = useUser();
  const navigate = useNavigate();
  const location = useLocation();
  const {isOpen, onOpen, onOpenChange} = useDisclosure();
  const [isLoading, setIsLoading] = useState(false);
  const [phone, setPhone] = React.useState("");

  const validateEmail = (phone) => {
    return phone.match(/^1[3-9]\d{9}$/);
  };

  const isInvalid = React.useMemo(() => {
    if (phone === "") return false;

    return validateEmail(phone) ? false : true;
  }, [phone]);

  function handleLogout() {
    setToken(undefined);
  }

  async function updatePhone(onClose: () => void) {
    const waiter = wait();
    setIsLoading(true)
    try {
      await APIClient.user.bindPhone(phone);
      await waiter;
      setPhone("");
      onClose(); // 关闭弹窗
    } finally {
      await waiter;
      setIsLoading(false);

    }
  }

  return (
    <div className="h-full w-full flex flex-col">
      <div className="h-14 px-10 shadow flex items-center">
        <Link to="/console" className="animate__animated animate__fadeIn">
          <div className="flex items-center">
            <BsRobot size={32}/>
            <div className="ml-2 text-large font-bold">大宝</div>
          </div>
        </Link>
        <div className={
          classNames(
            "h-full flex-1 flex justify-center items-center relative",
            "*:mx-6 *:text-[14px] *:cursor-pointer *:flex *:items-center"
          )
        }>
          <div className="animate__animated animate__fadeInDown *:mx-1"
               style={{animationDelay: '100ms'}}
               onClick={() => navigate("/console/knowledge-base")}>
            <div className={location.pathname.startsWith("/console/knowledge-base") ? "text-secondary" : ""}>
              <SlBookOpen size={18}/>
            </div>
            <div className={location.pathname.startsWith("/console/knowledge-base") ? "font-bold text-secondary" : ""}>
              知识库
            </div>
          </div>
          {user?.role === UserRole.Admin &&
            <div className="animate__animated animate__fadeInDown *:mx-1"
                 onClick={() => navigate("/console/bot")}>
              <div className={location.pathname.startsWith("/console/bot") ? "text-primary" : ""}>
                <RiRobot2Line size={18}/>
              </div>
              <div className={location.pathname.startsWith("/console/bot") ? "font-bold text-primary" : ""}>
                机器人
              </div>
            </div>
          }
          <div className={classNames(
            "absolute bottom-0",
            "transition-transform duration-500 ease-in-out",
            user?.role === UserRole.Admin ?
              (location.pathname.startsWith("/console/bot")
                ? "translate-x-[75px] *:bg-primary"
                : "translate-x-[-48px] *:bg-secondary") : "translate-x-[15px] *:bg-secondary"
          )}>
            <div className="h-1 w-12 animate__animated animate__fadeInUp"/>
          </div>
        </div>
        <div className="animate__animated animate__fadeIn">
          <Dropdown>
            <DropdownTrigger>
              <Button variant="light" radius="full" startContent={<FaRegUser size={20}/>}>
                {user?.name}
              </Button>
            </DropdownTrigger>
            <DropdownMenu variant="flat">
              <DropdownItem onClick={handleLogout} className="text-center">注 销</DropdownItem>
              <DropdownItem onPress={onOpen} color="primary" className="text-center">更换手机号</DropdownItem>
            </DropdownMenu>
          </Dropdown>
          <Modal isOpen={isOpen} onOpenChange={() => {
            setPhone('');
            onOpenChange()
          }} placement="top-center">
            <ModalContent>
              {(onClose) => (
                <>
                  <ModalHeader className="flex flex-col gap-1">请输入新的手机号</ModalHeader>
                  <ModalBody>
                    <Input autoFocus placeholder="手机号" autoComplete={"off"} variant="bordered"
                           value={phone}
                           isInvalid={isInvalid}
                           color={isInvalid ? "danger" : "success"}
                           errorMessage={isInvalid ? "请输入正确的手机号" : ""}
                           onValueChange={setPhone}
                    />
                  </ModalBody>
                  <ModalFooter>
                    <Button color="primary" isLoading={isLoading}
                            onClick={() => updatePhone(onClose)}>{isLoading ? '修改中' : '确认'}</Button>
                    <Button color="danger" onPress={onClose}>取消</Button>
                  </ModalFooter>
                </>
              )}
            </ModalContent>
          </Modal>
        </div>
      </div>
      <div className="flex-1 overflow-y-auto px-10 py-8">
        <Outlet/>
      </div>
    </div>
  )
}
